<template>
  <div>
    <div class="carregando-show" v-if="carregando">
      <Loading size="medium"></Loading>
    </div>
    <div class="erro-carregando" v-if="!carregando && erro">
      <ErroResultados></ErroResultados>
    </div>
    <template v-if="!carregando && !erro">
      <ExibirAtoInfralegal :ato-infralegal="atoInfralegal" />
      <DownloadPdf :url="urlPdf" />
    </template>
  </div>
</template>

<script>
import slugify from "slugify";

import AtoInfralegalApi from "../../services/AtoInfralegalApi";
import Loading from "../../components/Loading";
import ErroResultados from "../../components/ErroResultados";
import ExibirAtoInfralegal from "../../components/pesquisa_ato_infralegal/ExibirAtoInfralegal";
import titleAtoInfralegal from "../../helpers/atoInfralegal/titleAtoInfralegal";
import metaDados from "../../helpers/metaDados";
import DownloadPdf from "../../components/DownloadPdf";

const truncate = require("lodash.truncate");

export default {
  name: "PesquisaAtoInfralegalShow",
  metaInfo() {
    if (!this.atoInfralegal) {
      return {};
    }

    let title = titleAtoInfralegal(this.atoInfralegal);
    const description = truncate(this.atoInfralegal.conteudo, {length: 160});

    return {
      title,
      titleTemplate: "%s - Casa Civil do Estado de Goiás",
      meta: [
        {
          vmid: "description",
          name: "description",
          property: "description",
          content: description,
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: description,
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: `${title} - Casa Civil do Estado de Goiás`,
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          property: "twitter:description",
          content: description,
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          property: "twitter:title",
          content: `${title} - Casa Civil do Estado de Goiás`,
        },
        ...metaDados(this.$route),
      ],
    };
  },
  data() {
    return {
      carregando: true,
      erro: false,
      atoInfralegal: null,
      urlPdf: null,
    };
  },
  components: {
    Loading,
    ErroResultados,
    ExibirAtoInfralegal,
    DownloadPdf
  },
  created() {
    this.atoInfralegalApi = new AtoInfralegalApi();

    this.carregarAtoInfralegal();
  },
  watch: {
    $route: function(to, from) {
      if (to.params.id != from.params.id) {
        this.carregarAtoInfralegal();
      }
    },
  },
  methods:{
    carregarAtoInfralegal: async function() {
      try {
        this.carregando = true;
        this.erro = false;

        if (this.atoInfralegalApi.cancelTokens.getAtoInfralegal) {
          this.atoInfralegalApi.cancelTokens.getAtoInfralegal.cancel();
        }

        this.atoInfralegal = await this.atoInfralegalApi.getAtoInfralegal(
          this.$route.params.id
        );

        this.urlPdf = `${process.env.VUE_APP_BASE_API_URL}/api/v1/pesquisa/atos_infralegais/${this.atoInfralegal.id}/pdf`;

        const slug = this.createSlug();
        if (this.$route.params && this.$route.params.slug != slug) {
          this.$router.replace({
            name: "PesquisaAtoInfralegalShow",
            params: { ...this.$route.params, slug },
            query: this.$route.query,
          });
        }
        this.carregando = false;
      } catch (err) {
        if (this.atoInfralegalApi.isCancel(err)) {
          return;
        }
        console.log(err);

        this.erro = true;
        this.carregando = false;
        this.atoInfralegal = null;
      }
    },
    createSlug: function() {
      let tipoAtoInfralegal = "";
      if (
        this.atoInfralegal &&
        this.atoInfralegal.tipo_ato_infralegal &&
        this.atoInfralegal.tipo_ato_infralegal.nome
      ) {
        tipoAtoInfralegal = this.atoInfralegal.tipo_ato_infralegal.nome
          .toLowerCase()
          .replace(/ordinária|numerado/, "")
          .trim();
      }
      if (this.atoInfralegal && tipoAtoInfralegal) {
        const slug = `${tipoAtoInfralegal} ${this.atoInfralegal.numero}`;
        return slugify(slug, {
          lower: true,
          strict: true,
          locale: "pt",
        });
      }
      return undefined;
    },
  }
}
</script>
<style lang="scss" scoped>
.carregando-show {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.erro-carregando {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
</style>
